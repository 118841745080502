import React, {FC, ReactElement, ReactNode, useEffect, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Container,
  CssBaseline,
  CSSObject,
  Divider, Grid,
  List,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Theme,
  useTheme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { getI18n, useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import ListItemCustom from "../components/ListItemCustom";
import { useNavigate } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Logout } from "@mui/icons-material";
import { Countries } from "../helpers/CountryHelper";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import TopicIcon from "@mui/icons-material/Topic";
import useAuthenticatedRequest from "../helpers/SendAuthenticatedRequest";
import { setSelectedCompany } from "../store/slices/companiesSlice";
import { useAppDispatch } from "../store/hooks";
import configuration from "../../package.json";
import BoltIcon from '@mui/icons-material/Bolt';

interface AppBarProps {
  children?: ReactNode;
}
const drawerWidth = 256;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const LayoutContent = (props: AppBarProps): JSX.Element => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const { sendRequest } = useAuthenticatedRequest();
  const open = Boolean(anchorEl);

  useEffect(() => {
    fetchCompanies().then();
    changeLanguage("en").then();
  },[]);
  const onClickLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  const changeLanguage = async (language: string) => {
    const i18n = getI18n();
    await i18n.changeLanguage(language.toLowerCase());
    setSelectedLanguage(language);
  };
  const fetchCompanies = async () => {
    try {
      const response = await sendRequest<{ message: string }>({
        method: "GET",
        url: "Companies",
      });
      setCompanies(response.data);
      // @ts-ignore
      setSelectedCompanyId(response.data[0].id);
    } catch (error) {
    } finally {
    }
  };
  const handleDrawerOpen = () => {
    setDrawerOpen((prevState) => !prevState);
  };
  const onClickRouteButton = (route: string) => {
    navigate(route);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChangeLanguage = async (selectedLanguage: any) => {
    changeLanguage(selectedLanguage.toLowerCase());
  };

  const onChangeCompany = async (selectedCompanyId: string) => {
    dispatch(setSelectedCompany(selectedCompanyId));
    setSelectedCompanyId(selectedCompanyId);
  };

  return (
    <Container maxWidth={false}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ bgcolor: theme.palette.background.default }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "5px",
            }}
          >
            <MenuIcon />
          </IconButton>
          <img
            alt="Logo"
            src={"/logo.png"}
            width={"30px"}
            height={"30px"}
            style={{ marginRight: "10px" }}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {t("title")}
          </Typography>
          <Select
            id="InnerLayout-Select-Company"
            size={"small"}
            sx={{ width: "200px", marginRight: "15px" }}
            value={selectedCompanyId}
            onChange={(selected) => onChangeCompany(selected?.target?.value)}
          >
            {companies
              .sort((a: any, b: any) => a.name.localeCompare(b.name))
              .map((item: any) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          <Select
            id="InnerLayout-Select-Language"
            size={"small"}
            sx={{ width: "200px", marginRight: "15px" }}
            value={selectedLanguage}
            autoWidth={true}
            onChange={(selected) => onChangeLanguage(selected?.target?.value)}
          >
            {Countries.map((country) => {
              return (
                <MenuItem
                  value={country.code}
                  key={country.code}
                  selected={country.default}
                >
                  <Stack direction={"row"} width={"165px"}>
                    {
                      <img
                        key={"LayoutContent-Img-Select-Language"}
                        loading="lazy"
                        width="20"
                        height="20"
                        style={{ borderRadius: "50%", marginRight: "10px" }}
                        srcSet={`https://flagcdn.com/w40/${country.flagCode}.png 2x`}
                        src={`https://flagcdn.com/w20/${country.flagCode}.png`}
                        alt=""
                      />
                    }
                    {t(`languages.${country.code}`)}
                  </Stack>
                </MenuItem>
              );
            })}
          </Select>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="end"
            onClick={handleClick}
            style={{ marginRight: "0px" }}
          >
            <AccountCircleRoundedIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "#121212",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={onClickLogout}>
              <ListItemIcon>
                <Logout fontSize="small" color={"primary"} />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={drawerOpen}>
        <DrawerHeader />
        <List
          component="nav"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              hidden={!drawerOpen}
            >
              {t("navigation.headers.data")}
            </ListSubheader>
          }
        >
          <ListItemCustom
            translationKey="pages.events.navigation.title"
            isOpen={drawerOpen}
            icon={<DashboardIcon />}
            onClick={() => onClickRouteButton("/")}
            route={"/"}
          />
        </List>
        <Divider />
        <List
          component="nav"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              hidden={!drawerOpen}
            >
              {t("navigation.headers.administration.customer")}
            </ListSubheader>
          }
        >
          <ListItemCustom
            translationKey="pages.projects.navigation.title"
            isOpen={drawerOpen}
            icon={<TopicIcon />}
            onClick={() => onClickRouteButton("/projects")}
            route={"/projects"}
          />
          <ListItemCustom
            translationKey="pages.subscriptions.navigation.title"
            isOpen={drawerOpen}
            icon={<SubscriptionsIcon />}
            onClick={() => onClickRouteButton("/subscriptions")}
            route={"/subscriptions"}
          />
          <ListItemCustom
              translationKey="pages.triggers.navigation.title"
              isOpen={drawerOpen}
              icon={<BoltIcon />}
              onClick={() => onClickRouteButton("/triggers")}
              route={"/triggers"}
          />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, paddingLeft: drawerOpen ? 30 : 10, paddingBottom: 3 }}
      >
        <DrawerHeader />
        <Paper
          elevation={10}
          sx={{
            backgroundColor: theme.palette.background.paper,
            margin: "10px",
            padding: "16px"
          }}
        >
          {props.children}
        </Paper>
        {/*<Container maxWidth={false}>{props.children}</Container>*/}
      </Box>
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, height: 22, flex: 1, flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#1e1e1e' }}>
          <Typography variant="overline" sx={{ lineHeight: 1.2, paddingLeft: 1, paddingTop: 0.5}}>
            © 2024 Sunato Stuttgart GmbH All rights reserved.
          </Typography>
          <Typography variant="overline" sx={{ lineHeight: 1.2, paddingRight: 1, paddingTop: 0.5}}>
            Version { configuration.version }
          </Typography>
      </AppBar>
    </Container>
  );
};
export default LayoutContent;
